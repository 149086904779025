
.nav-container {
    position: relative;
    top: -5vh;
    margin: 0 auto;
    width: 80%;
}

.nav-main {
    display: flex;
    font-size: 3vw;
    justify-content: center;

    & > span {
        padding: 5px;
        margin: 0 3vw;
        & > a {
            text-decoration: none;
            color: grey;
            transition: color 1s;
            font-size: 3vh;
        }
        & > a:hover {
            // text-shadow: 0px 1px 5px rgba(0,0,0,.5);
            color: black;
        }
    }
}

span.nav-selected {
    border-bottom: 1px solid black;
    & > a {
        color: black;
    }
}

.nav-highlight {
    width: 100%;
    height: 5px;
    margin: 0;
    & > div {
        height: 2px;
        background-color: black;
        width: 200px;
        margin: 0;
    }
}