.planner-topMenu {
    box-shadow: 5px 5px 15px rgba(0,0,0,0.1);
    padding: 25px 0 20px;
    height: 60px;
    text-align: center;
    box-sizing: border-box;
    & > span {
        cursor: pointer;
        margin: 0 50px;
        color: salmon;
    }
}