.header {
    width: 100%;
    // height: auto;
    // height: 50vh;
    overflow-y: visible;
}

.insta {
    width: 100%;
    /* height: 100%; */
    background-color: black;
    z-index: -1;
    line-height: 0;
    flex: wrap;
}

.topbar {
    position: absolute;
    top: -16vh;
    height: 20%;
    width: 101%;
    z-index: 2;
    background-color: white;
    transform: rotate(-5deg) skewX(-5deg);

    /* left: -0.1vw; */
}

.lower-section {
    position: relative;
    top: -17vw;
}

.bottombar {
    position: relative;
    top: -12%;
    left: -0.5vw;
    height: 15vw;
    width: 101%;

    background-color: white;
    /* background-color: rgba(255,255,255,0.8); */
    transform: rotate(-5deg) skewX(-5deg);
    margin: 0;
    z-index: 2;
}

.bartext {
    margin: 0;
    margin-right: 1vw;
    text-align: right;
    font-size: 11vw;
    font-weight: 900;
    color: white;
    transform: rotate(-5deg) skewX(-5deg);

    position: relative;
    top: 1.5vw;
}
.text {
    margin: 0 1.5vw;
    text-align: right;
    font-size: 4.55vw;
    font-weight: 800;
    color: black;
    transform: skewX(-5deg);
    /* line-height: 4.55vw; */
    /* position: relative; */
    /* top: -15vh; */
    /* z-index: 1000000; */
}

.text:nth-child(2n) {
    line-height: 3vw;
}

.image {
    width: 100%;
    height: 100%;
    opacity: 0.6;
}

.imgcontainer {
    max-width: calc(100vw / 6);
    max-height: 100%;
    opacity: 0.7;
    display: inline-block;
    overflow: hidden;
}
  
.noimg {
    height: calc(100vw / 6);
    // height: calc(100vw / 12);
    // width: 100%;
    width: calc(100vw / 6);
    background: black;
}
